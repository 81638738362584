<script setup lang="ts">
const { isMobile, isDesktop } = useDevice();
const { clientType, imgDesktop, imgMobile, label, linkUrl, type, description, title } =
  defineProps<{
    clientType: string;
    imgDesktop: string;
    imgMobile: string;
    label: string;
    linkUrl: string;
    type: string;
    description: string;
    title: string;
  }>();
</script>

<template>
  <div class="clients-push" :class="clientType">
    <div
      v-if="isDesktop"
      class="clients-push-bg"
      :style="{ backgroundImage: 'url(' + imgDesktop + ')' }"
      role="img"
      :aria-label="'photo representant un ' + clientType"
    ></div>
    <div>
      <h2 class="clients-push-title">{{ title }}</h2>
      <figure role="none" with="390" height="177">
        <NuxtImg
          v-if="isMobile"
          class="clients-push-image"
          :src="imgMobile"
          alt="image d'un client"
          with="390"
          height="177"
          loading="lazy"
          title="Image d'un client"
        />
      </figure>
      <p class="clients-push-description">{{ description }}</p>
      <div class="clients-push-cta">
        <ButtonLink
          :label="label"
          :url="linkUrl"
          :type="type"
          :reading-button="true"
          :is-custom="true"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.clients-push {
  color: $white;
  display: flex;
  border-radius: 0px 3px 3px 0px;
  padding: $gutter * 3 0;
  position: relative;
  height: 100%;
  @include mq(desktop) {
    padding: 0;
  }
  &.candidat {
    background-color: $primary-color;
    .clients-push-description {
      @include mq(desktop) {
        margin-bottom: $gutter * 4;
      }
    }
  }
  &.recruteur {
    background-color: $etab-color;
  }
  &-title {
    font-weight: 800;
    text-align: center;
    padding: 0 var(--showcase-size-100);
    font-size: 24px;
    line-height: 35px;
    @include mq(desktop) {
      font-size: 27px;
      padding: $gutter * 3 var(--showcase-size-100);
    }
  }
  &-image {
    position: relative;
    left: 0;
    width: 100%;
    margin: var(--showcase-size-100) 0 $gutter;
    max-height: 150px;
    object-fit: cover;
    object-position: top;
  }
  &-description {
    padding: var(--showcase-size-100);
    text-align: center;
    line-height: 25px;
  }
  &-cta {
    display: flex;
    justify-content: center;
    margin-top: $gutter;
  }
  &-bg {
    background-repeat: no-repeat;
    @include mq(desktop) {
      min-width: 350px;
      background-size: cover;
    }
  }
}
</style>

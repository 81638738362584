export const generateOrganizationJsonLd = () => {
  return JSON.stringify({
    "@context": "https://schema.org",
    "@type": "Organization",
    image:
      "https://lh3.googleusercontent.com/p/AF1QipNHMbQRXa-eHfs06pvBZNHYISnZZKMNfe3L4nvw=s680-w680-h510",
    url: "https://www.extracadabra.com",
    logo: "https://staticadabra.s3.amazonaws.com/logo.svg",
    name: "Extracadabra",
    email: "contact@extracadabra.com",
    address: {
      "@type": "PostalAddress",
      streetAddress: "82, rue d'Hauteville",
      addressLocality: "Paris",
      addressCountry: "FR",
      addressRegion: "Ile-de-France",
      postalCode: "75010",
    },
    vatID: "FR59813761566",
  });
};

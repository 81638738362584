<script setup lang="ts">
const { isMobile, isDesktop } = useDevice();
const { title, subtitlePart1, subtitlePart2, label, bgColor, corner, type, linkUrl } =
  defineProps<{
    title: string;
    subtitlePart1: string;
    subtitlePart2: string;
    label: string;
    corner: string;
    type: string;
    bgColor: string;
    linkUrl: string;
  }>();
</script>

<template>
  <div class="hub-clients-item" :class="[corner, type]">
    <span class="hub-clients-item-title-wrapper">
      <span class="title">{{ title }}</span>
      <span v-if="isDesktop" class="subtitle">
        <span class="part1">{{ subtitlePart1 }}</span>
        <!-- eslint-disable-next-line vue/no-v-html -->
        <span v-html="subtitlePart2" />
      </span>
    </span>
    <NuxtLink class="hub-clients-push-link" :to="linkUrl">
      <div class="hub-clients-item-cta">
        <p v-if="isMobile">
          <span class="part1">{{ subtitlePart1 }}</span>
          <!-- eslint-disable-next-line vue/no-v-html -->
          <span v-html="subtitlePart2" />
        </p>
        <div class="hub-clients-item-cta-fake">
          <ButtonLink :label="label" :url="linkUrl" :type="type" :reading-button="true" />
        </div>
      </div>
    </NuxtLink>
  </div>
</template>

<style lang="scss" scoped>
.hub-clients-item {
  height: 340px;
  width: 100%;
  text-align: center;
  position: relative;
  @include mq(desktop) {
    height: 322px;
  }
  &-title-wrapper {
    @include mq(desktop) {
      display: flex;
      flex-wrap: wrap;
      height: calc(100% - 112px);
      padding-right: $gutter * 4;
      width: 100%;
    }
    .title,
    .subtitle {
      @include mq(desktop) {
        justify-content: end;
        width: 100%;
        text-align: right;
        color: $white;
      }
    }
    .title {
      color: $white;
      font-weight: 800;
      font-size: 22px;
      text-transform: uppercase;
      text-align: center;
      display: flex;
      padding: $gutter * 3 var(--showcase-size-100);
      @include mq(desktop) {
        justify-content: end;
        font-size: 28px;
        align-items: end;
        padding: 0;
        height: 50%;
        margin-bottom: $gutter;
      }
    }
    .subtitle {
      @include mq(desktop) {
        font-weight: 800;
        height: 50%;
        align-items: start;
        span {
          font-size: 21px;
        }
      }
    }
  }
  &-cta {
    background-color: $secondary-color;
    color: $white;
    height: 112px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: -112px;
    width: 100%;
    padding: 10px 0;
    @include mq(desktop) {
      bottom: 0;
      justify-content: end;
      padding-right: $gutter * 4;
    }
    p {
      font-size: 20px;
      .part1 {
        color: v-bind(bgColor);
        display: block;
        width: 100%;
        font-size: 20px;
        font-weight: 800;
      }
    }
    &-fake {
      pointer-events: none;
    }
  }
  &.candidat {
    background: url("/img/candidat.webp") v-bind(bgColor) bottom no-repeat;
    background-size: contain;
    @include mq(desktop) {
      background: none;
      background-color: v-bind(bgColor);
      position: relative;
      &:before {
        content: "";
        display: block;
        height: 345px;
        width: 356px;
        background: url("/img/candidat-desktop.webp") no-repeat;
        position: absolute;
        left: 15px;
        bottom: 0;
        z-index: 1;
      }
    }
    .subtitle {
      @include mq(desktop) {
        .part1 {
          color: $primary-color-800;
        }
      }
    }
  }
  &.recruteur {
    background: url("/img/recruteur.webp") v-bind(bgColor) bottom no-repeat;
    background-size: contain;
    @include mq(desktop) {
      background: none;
      background-color: v-bind(bgColor);
      position: relative;
      &:before {
        content: "";
        display: block;
        height: 364px;
        width: 304px;
        background: url("/img/recruteur-desktop.webp") no-repeat;
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 1;
        background-size: 96%;
        background-position: left bottom;
      }
    }
    .subtitle {
      @include mq(desktop) {
        .part1 {
          color: $etab-color-300;
        }
      }
    }
  }
  &.top-right {
    border-radius: 0 12px 0 0;
  }
  &.top-left {
    border-radius: 12px 0 0 0;
  }
}
</style>

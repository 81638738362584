<script setup lang="ts">
const { isMobile, isDesktop } = useDevice();
const { imgDesktop, imgMobile, imgAlt, description, title, fullwidth, bgColor } =
  defineProps<{
    imgDesktop: string;
    imgMobile: string;
    imgAlt: string;
    description: string;
    title: string;
    fullwidth?: boolean;
    bgColor?: string;
  }>();
</script>

<template>
  <div
    class="edito-with-image"
    :class="{ fullwidth: fullwidth }"
    :style="{ 'background-color': bgColor || '#f5f5f5' }"
  >
    <div class="edito-with-image-container">
      <h2 v-if="isMobile" class="edito-with-image-title">{{ title }}</h2>
      <figure role="none" class="edito-with-image-photo">
        <NuxtImg
          v-if="isMobile"
          :src="imgMobile"
          :alt="imgAlt"
          loading="lazy"
          :title="imgAlt"
        />
        <NuxtImg
          v-if="isDesktop"
          :src="imgDesktop"
          :alt="imgAlt"
          loading="lazy"
          :title="imgAlt"
        />
      </figure>
      <div class="edito-with-image-content">
        <h2 v-if="isDesktop" class="edito-with-image-content--title">{{ title }}</h2>
        <p class="edito-with-image-content--description">{{ description }}</p>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.edito-with-image {
  &.fullwidth {
    position: absolute;
    left: 0;
    width: 100%;
    padding: $gutter * 4 0;
    @include mq(desktop) {
      padding: 0;
      margin-top: 430px;
    }
  }
  &-container {
    max-width: $grid-max-width;
    margin: 0 auto;
    position: relative;
    @include mq(desktop) {
      display: flex;
    }
  }
  &-title {
    text-align: center;
    font-weight: 800;
    color: $dark;
    margin-bottom: $gutter * 4;
  }
  &-photo {
    margin-bottom: $gutter * 4;
    @include mq(desktop) {
      margin: 0 $gutter * 4 0 $gutter;
    }
    img {
      width: 100%;
      @include mq(desktop) {
        min-width: 640px;
      }
    }
  }
  &-content {
    @include mq(desktop) {
      padding: $gutter * 5 0 0;
    }
    &--title {
      font-weight: 800;
      color: $dark;
      margin-bottom: $gutter * 4;
    }
    &--description {
      padding: 0 var(--showcase-size-100);
      color: $dark;
      font-size: 16px;
      @include mq(desktop) {
        padding: 0;
        line-height: 25px;
        padding-right: $gutter * 10;
      }
    }
  }
}
</style>

<script setup lang="ts">
const { isMobile, isDesktop } = useDevice();
const { resssourceName, imgDesktop, imgMobile, url, description, external, idx } =
  defineProps<{
    resssourceName: string;
    imgDesktop: string;
    imgMobile: string;
    url: string;
    labelBtn: string;
    description: string;
    external?: boolean;
    idx?: number;
  }>();
</script>

<template>
  <div class="push-ressources">
    <NuxtLink
      :to="url"
      class="push-ressources-link"
      :target="external ? '_blank' : '_self'"
      :data-idx="idx"
    >
      <NuxtImg
        v-if="isMobile"
        :src="imgMobile"
        :alt="`photo d'une personne qui représente ${resssourceName}`"
        loading="lazy"
        width="101"
        :title="`photo d'une personne qui représente ${resssourceName}`"
      />
      <NuxtImg
        v-if="isDesktop"
        :src="imgDesktop"
        :alt="`photo d'une personne qui représente ${resssourceName}`"
        loading="lazy"
        :title="`photo d'une personne qui représente ${resssourceName}`"
      />

      <div class="push-ressources-infos">
        <span class="push-ressources-infos-name">{{ resssourceName }}</span>
        <div class="push-ressources-infos-description">
          <p class="push-ressources-infos-description-text">{{ description }}</p>

          <ButtonLink
            class="push-ressources-infos-description-cta"
            :url="url"
            type="candidat"
            :label="labelBtn"
            size="small"
            :external="external"
            :reading-button="true"
          />
        </div>
      </div>
    </NuxtLink>
  </div>
</template>

<style lang="scss" scoped>
.push-ressources {
  border-radius: 3px;
  display: flex;
  &-link {
    display: flex;
    text-decoration: none;
    img {
      object-fit: cover;
      min-width: 101px;
      @include mq(desktop) {
        min-width: 220px;
      }
    }
    &[data-idx="1"] {
      flex-direction: row-reverse;
    }
  }
  &-infos {
    display: flex;
    flex-wrap: wrap;
    &-name {
      background-color: $primary-color;
      width: 100%;
      color: $white;
      text-transform: uppercase;
      font-weight: 800;
      text-align: center;
      height: 37px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0 3px 0 0;
      @include mq(desktop) {
        height: 52px;
      }
    }
    &-description {
      background-color: $secondary-color;
      color: $white;
      text-align: center;
      padding: var(--showcase-size-100) var(--showcase-size-100) var(--showcase-size-100);
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      border-radius: 0 0 3px 0;
      @include mq(desktop) {
        padding: var(--showcase-size-100) var(--showcase-size-100) $gutter * 3;
      }
      &-text {
        margin-bottom: var(--showcase-size-100);
        font-size: 14px;
        padding-top: 0;
        line-height: 20px;
        @include mq(desktop) {
          margin-bottom: $gutter * 3;
          padding-top: var(--showcase-size-100);
          font-size: 16px;
          line-height: 23px;
        }
      }
    }
  }
}
</style>

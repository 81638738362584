<script setup lang="ts">
import type { FaqClassic } from "~/models/Faq";

const { title, type, faqItems } = defineProps<{
  title: string;
  type: string;
  faqItems: FaqClassic[];
}>();

const activeContainerIndex = ref<number | null>(null);
const handleQuestionClick = (index: number) => {
  if (activeContainerIndex.value === index) {
    activeContainerIndex.value = null;
  } else {
    activeContainerIndex.value = index;
  }
};
</script>

<template>
  <div :class="`col-xs-12 col-md-6 faq__section faq__section--${type}`">
    <span class="faq__section__title">{{ title }}</span>
    <div
      v-for="(faq, index) in faqItems"
      :key="index"
      class="faq__section__container"
      :class="{ active: activeContainerIndex === index }"
    >
      <div class="faq__section__question" @click="handleQuestionClick(index)">
        <h2 class="faq__section__question__text">{{ faq.question }}</h2>
        <i role="presentation" class="plus extra-plus"></i>
        <i role="presentation" class="minus extra-minus"></i>
      </div>
      <div class="faq__section__answer" v-html="faq.answer" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.faq {
  &__section {
    @include mq(desktop) {
      flex-basis: 49%;
    }
    &__container {
      margin-bottom: var(--showcase-size-050);
      &.active {
        .faq__section__question {
          background-color: var(--showcase-color-secondary-300);
          color: var(--showcase-color-additional-light);
          .minus {
            display: flex;
          }
          .plus {
            display: none;
          }
        }
        .faq__section__answer {
          max-height: 1000px;
          padding: var(--showcase-size-100);
        }
      }
    }
    &__title {
      display: flex;
      font-weight: 800;
      justify-content: flex-start;
      text-transform: uppercase;
      margin-bottom: var(--showcase-size-100);
    }
    &__question {
      padding: var(--showcase-size-100);
      border: 2px solid transparent;
      display: flex;
      justify-content: space-between;
      font-weight: 800;
      color: var(--showcase-color-secondary-300);
      border-color: var(--showcase-color-secondary-300);
      &__text {
        font-size: var(--showcase-size-100);
        line-height: 20px;
      }
      &:hover {
        color: var(--showcase-color-additional-light);
        background-color: var(--showcase-color-secondary-300);
      }
      i {
        margin-left: var(--showcase-size-100);
        align-items: center;
        &.plus {
          display: flex;
        }
        &.minus {
          display: none;
        }
      }

      &:hover {
        cursor: pointer;
      }
    }
    &__answer {
      max-height: 0;
      overflow: hidden;
      line-height: 26px;
      font-size: 14px;
    }
    &--candidat {
      .faq__section {
        &__title {
          color: var(--showcase-color-primary-200);
        }
        &__answer {
          background-color: var(--showcase-color-primary-100);
        }
      }
    }
    &--recruteur {
      .faq__section {
        &__title {
          color: var(--showcase-color-secondary-200);
        }
        &__answer {
          background-color: var(--showcase-color-secondary-100);
        }
      }
    }
  }
}
</style>

export const generateSoftwareApplicationJsonLd = () => {
  return JSON.stringify({
    "@context": "http://schema.org",
    "@type": "SoftwareApplication",
    name: "Extracadabra",
    operatingSystem: "Web-based",
    applicationCategory: "Software",
    aggregateRating: {
      "@type": "AggregateRating",
      ratingValue: "4.2",
      ratingCount: "246",
    },
    offers: {
      "@type": "Offer",
      price: "0",
      priceCurrency: "EUR",
    },
    sameAs: [
      "https://www.facebook.com/extracadabra",
      "https://www.tiktok.com/@extracadabra",
      "https://www.instagram.com/extracadabra/",
      "https://www.linkedin.com/company/extracadabra/",
      "https://www.youtube.com/@Extracadabra-app",
    ],
  });
};
